import {
    GET_SHOP,
    INITIALIZE_SHOPPING_CART,
    UPDATE_SHOP,
    ADD_TO_SHOPPING_CART,
    REMOVE_FROM_SHOPPING_CART,
    CLEAR_SHOP,
    SHOP_ERROR,
    SET_FRAIS_ADMIN,
    SET_TAUX_TAXES,
    CLEAR_TIMEOUT_SHOP,
    UPDATE_PROMOTIONS,
    INITIALIZE_PROMOTIONS,
    CLEAR_PROMOTION,
    REMOVE_PROMOTION,
    RECALCULER_SHOP, UPDATE_MONTANT_DEPOT
} from "../components/actions/types";
import {CartItemType} from "../components/actions/shop";
import {forEach} from "react-bootstrap/ElementChildren";

const initialState = {
    loading: true,
    cart: [
        /*{
          name: "HÉBERGEMENT ANNUEL",
          thumbnail: "thumbnails/activites-506x337.jpg",
          montant: 199.99, tps: 10, tvq: 19.95, total: 229.94,
          type: "forfait",
          inclus: [
            {
              name: "Séance spa gratuite"
            },
            {
              name: "Souper fondu"
            }
          ]
        },
        {// idZec: lausanne
          isReservation: true,
          name: "Unité-001",
          montant: 25,
          tps: 2.25,
          tvq: 4.4887500000000005,
          total: 45.73875,
          type: "Reservation Camping",
          inclus: [
            { name: "Arrivée", value: "Tue May 11 2021 00: 00: 00 GMT - 0400(heure avancée de l’Est)" },
            { name: "Départ", value: "Wed May 12 2021 00: 00: 00 GMT - 0400(heure avancée de l’Est)" },
            { name: "IdEquipement", textVal: "CARAVANE À SELLETTE", value: "997d2a30-62e4-e811-be4b-70c94e872d18" },
            { name: "Longueur", textVal: "25 PI ", value: "25" },
            { name: "Adultes", textVal: "1 ADULTE", value: "1" },
            { name: "Enfants", textVal: "1 ENFANT", value: "1" },
          ],
          selectedUnite: {
            description: "001",
            dimension_equipment: "25-40",
            id_type_unite: "86bc5d75-3ae4-e811-be4b-70c94e872d18",
            id_unite: "f492611a-40e4-e811-be4b-70c94e872d18",
            id_unite_categorie: "00000000-0000-0000-0000-000000000000",
            plan_base: {
              forfait_global: 0,
              forfait_individuel: 0,
              forfait_journalier: 1,
              id_unite: "f492611a-40e4-e811-be4b-70c94e872d18",
              prix: 25,
              prix_enfant: 0,
              tps_incluse: false,
              tvq_incluse: false
            },
            selectedAdultes: "1",
            selectedArrivee: "2021-05-11T04:00:00.000Z",
            selectedDepart: "2021-05-12T04:00:00.000Z",
            selectedEnfants: "1",
            selectedIdAmperage: "",
            selectedIdEquipement: "997d2a30-62e4-e811-be4b-70c94e872d18",
            selectedLongueur: "25",
            selectedService: -1,
            type_hebergement: "CP",
          }
        }*/
    ],
    promotions: [],
    subtotal: 0, //244.99,
    fraisAdmin: 0,
    tpsAvantFraisAdmin: 0,
    tvqAvantFraisAdmin: 0,
    tps: 0, //12.25,
    tvq: 0, //24.44375,
    total: 0, //288.68375,
    fraisAdminFixe: null,
    fraisAdminPourcent: null,
    tauxTps: null,
    tauxTvq: null,
    tauxHebergement: null,
    error: {},
    montantDepots: 0
};

function roundTwoDecimals(n) {
    return Math.round(Number(n) * 100) / 100;
}

function updateMontantsWithFraisAdmin(state) {
    state = reCalculShoppingCartStateAmount(state);
    var subTotal = roundTwoDecimals(state.subtotal);
    state.fraisAdmin = roundTwoDecimals(subTotal === 0 ? 0 : (state.fraisAdminFixe || 0) + (state.fraisAdminPourcent || 0) * subTotal * 0.01);
    state.tps = roundTwoDecimals(state.tpsAvantFraisAdmin + (state.fraisAdmin + state.hebergement) * (state.tauxTps || 0) * 0.01);
    state.tvq = roundTwoDecimals(state.tvqAvantFraisAdmin + (state.fraisAdmin + state.hebergement) * (state.tauxTvq || 0) * 0.01 + state.tpsAvantFraisAdmin + (state.fraisAdmin + state.hebergement) * (state.tauxTps || 0) * 0.01 - state.tps);

    return state;
}

function reCalculShoppingCartStateAmount(state) {
    state.tpsAvantFraisAdmin = roundTwoDecimals(state.cart.reduce((tps, item) => tps + item["tps"], 0));
    state.tvqAvantFraisAdmin = roundTwoDecimals(state.cart.reduce((tvq, item) => tvq + item["tvq"], 0));
    state.hebergement = state.cart.reduce((mntHeb, item) => mntHeb + item["hebergement"], 0);
    state.subtotal = roundTwoDecimals(state.cart.reduce((montant, item) => montant + item["montant"], 0));
    state.total = roundTwoDecimals(state.subtotal + state.tpsAvantFraisAdmin + state.tvqAvantFraisAdmin + state.hebergement);
    //state.montantDepots = roundTwoDecimals(state.cart.reduce((montantDepots, item) => montantDepots + item["montantDepots"], 0));
    return state;
}

export default function (state = initialState, action) {
    const {
        type,
        payload
    } = action;
    switch (type) {
        case GET_SHOP: {
            return {
                ...state,
                //...state.cart,   // les items d'achat doivent être dans la carte uniquement.
                loading: false
            };
        }

        case SET_FRAIS_ADMIN: {
            return updateMontantsWithFraisAdmin({
                ...state,
                fraisAdminFixe: action.fixe,
                fraisAdminPourcent: action.pourcent,
                loading: false
            });
        }

        case SET_TAUX_TAXES: {
            return updateMontantsWithFraisAdmin({
                ...state,
                tauxTps: action.tps,
                tauxTvq: action.tvq,
                tauxHebergement: action.hebergement,
                loading: false
            });
        }

        case UPDATE_SHOP: {
            const id = action.index;
            const newCart = [...state.cart];
            const montant = newCart[id].montant;
            const tps = newCart[id].tps;
            const tvq = newCart[id].tvq;

            Object.entries(payload).map(([key, val], i) => (newCart[id][key] = val));
            newCart[id].montant = roundTwoDecimals(payload.montant);
            newCart[id].inclus = payload.inclus;
            newCart[id].tps = payload.tps;
            newCart[id].tvq = payload.tvq;
            newCart[id].total = payload.total;
            sessionStorage.setItem("cart", JSON.stringify(newCart));
            return updateMontantsWithFraisAdmin({
                ...state,
                cart: newCart,
                subtotal: state.subtotal + Number(newCart[id].montant) - Number(montant),
                tpsAvantFraisAdmin: state.tpsAvantFraisAdmin + Number(newCart[id].tps) - Number(tps),
                tvqAvantFraisAdmin: state.tvqAvantFraisAdmin + Number(newCart[id].tvq) - Number(tvq),
                loading: false,
                //montantDepots: state.montantDepots + payload.montantDepots
            });
        }
        case CLEAR_PROMOTION: {
            sessionStorage.removeItem("promotions");
            return {
                ...state,
                promotions: []
            }
        }
        case UPDATE_PROMOTIONS : {
            if (!state.promotions.find(s => s.code === payload.code)) {
                const newPromotions = [...state.promotions, payload]

                var montantPromotion = 0;
                var montantPromotionTps = 0;
                var montantPromotionTvq = 0;
                var montantPromotionHerbergement = 0;
                var montantPromotionApresTaxe = 0;

                newPromotions.map((promotion) => {
                    montantPromotion = promotion.montantPromotion.montantAvantTaxes;
                    montantPromotionTps += promotion.montantPromotion.tps;
                    montantPromotionTvq += promotion.montantPromotion.tvq;
                    montantPromotionHerbergement += promotion.montantPromotion.hebergement;
                    montantPromotionApresTaxe = promotion.montantPromotion.total;
                });

                state.tps -= montantPromotionTps;
                state.tvq -= montantPromotionTvq;
                state.subtotal -= montantPromotion;
                state.total -= montantPromotionApresTaxe;

                sessionStorage.setItem("promotions", JSON.stringify(newPromotions));
                return {
                    ...state,
                    promotions: newPromotions
                };
            } else {
                return {...state};
            }
        }

        case UPDATE_MONTANT_DEPOT:
            if (payload.montant > state.total)
                state.montantDepots = state.total;
            else
                state.montantDepots = payload.montant;
            sessionStorage.setItem("cart", JSON.stringify(state.cart));
            return {...state};

        case REMOVE_PROMOTION: {
            const promotions = state.promotions.filter(f => f.code !== payload.code);
            sessionStorage.setItem("promotions", JSON.stringify(promotions));
            return {
                ...updateMontantsWithFraisAdmin(state),
                promotions: promotions
            }
        }

        case RECALCULER_SHOP: {

        }

        case INITIALIZE_PROMOTIONS: {
            return {
                ...state,
                promotions: payload || []
            };
        }

        case INITIALIZE_SHOPPING_CART: {
            const cartLength = payload.cart.length;
            let subtotal = 0;
            let tpsAvantFraisAdmin = 0;
            let tvqAvantFraisAdmin = 0;
            let montantDepot = 0;
            let tps = 0, tvq = 0, total = 0, hebergement = 0;

            for (let i = 0; i < cartLength; i++) {
                const newCartItem = payload.cart[i];
                subtotal += roundTwoDecimals(newCartItem.montant);
                tpsAvantFraisAdmin += newCartItem.tps;
                tvqAvantFraisAdmin += newCartItem.tvq;
                montantDepot += newCartItem.montantDepots;
                tps += roundTwoDecimals(newCartItem.tps);
                tvq += roundTwoDecimals(newCartItem.tvq);
                total += roundTwoDecimals(newCartItem.total);
                hebergement += roundTwoDecimals(newCartItem.hebergement);
            }

            payload.promotions.forEach((promotion) => {
                subtotal -= roundTwoDecimals(promotion.montantPromotion.montantAvantTaxes);
                tps -= roundTwoDecimals(promotion.montantPromotion.tps);
                tvq -= roundTwoDecimals(promotion.montantPromotion.tvq);
                total -= roundTwoDecimals(promotion.montantPromotion.total);
                hebergement -= roundTwoDecimals(promotion.montantPromotion.hebergement);
            })
            return {
                ...state,
                cart: payload.cart || [],
                promotions: payload.promotions || [],
                subtotal: roundTwoDecimals(subtotal),
                tpsAvantFraisAdmin,
                tvqAvantFraisAdmin,
                tps: roundTwoDecimals(tps),
                tvq: roundTwoDecimals(tvq),
                hebergement: roundTwoDecimals(hebergement),
                total: roundTwoDecimals(total),
                loading: false,
                montantDepots: montantDepot
            };
        }

        case
        ADD_TO_SHOPPING_CART: {
            let newCart = [...state.cart, payload];
            sessionStorage.setItem("cart", JSON.stringify(newCart));
            return updateMontantsWithFraisAdmin({
                ...state,
                cart: newCart,
                subtotal: state.subtotal + roundTwoDecimals(payload.montant) + roundTwoDecimals(payload.hebergement) + roundTwoDecimals(payload.fraisAdmin),
                tpsAvantFraisAdmin: state.tpsAvantFraisAdmin + payload.tps,
                tvqAvantFraisAdmin: state.tvqAvantFraisAdmin + payload.tvq,
                loading: false,
                //montantDepots: state.montantDepots + payload.montantDepots
            });
        }

        case
        REMOVE_FROM_SHOPPING_CART: {
            let newCart;
            if (payload.id != null) newCart = state.cart.filter((item) => item.id !== payload.id);
            else newCart = state.cart.filter((item) => item !== payload);
            sessionStorage.setItem("cart", JSON.stringify(newCart));
            return updateMontantsWithFraisAdmin({
                ...state,
                cart: newCart,
                // cart: payload.id != null ? state.cart.filter(item => item.id !== payload.id) : state.cart.filter(item => item !== payload),
                subtotal: state.subtotal - roundTwoDecimals(payload.montant),
                montantDepots: state.montantDepots - roundTwoDecimals(payload.montantDepots),
                tpsAvantFraisAdmin: state.tpsAvantFraisAdmin - payload.tps,
                tvqAvantFraisAdmin: state.tvqAvantFraisAdmin - payload.tvq,
                loading: false
            });
        }

        case
        CLEAR_TIMEOUT_SHOP: {
            let subtotal = 0;
            let tps = 0;
            let tvq = 0;
            let montantDepot = 0;

            sessionStorage.removeItem("cart");
            sessionStorage.removeItem("promotions");
            state.cart.map((item) => {
                if (item.type === CartItemType.ReservationActivite) {
                    subtotal += item.montant;
                    tvq += item.tvq;
                    tps += item.tps;
                    //montantDepot += item.montantDepots;
                }
            });

            const newCart = state.cart.filter((item) => item.type !== CartItemType.ReservationActivite);
            return updateMontantsWithFraisAdmin({
                ...state,
                promotions: [],
                cart: newCart,
                subtotal: roundTwoDecimals(state.subtotal - subtotal),
                tpsAvantFraisAdmin: state.tpsAvantFraisAdmin - roundTwoDecimals(tps),
                tvqAvantFraisAdmin: state.tvqAvantFraisAdmin - roundTwoDecimals(tvq),
                loading: false,
                //montantDepots: state.montantDepots - montantDepot
            });
        }

        case
        CLEAR_SHOP: {
            return {
                ...state,
                cart: null,
                loading: false
            };
        }

        case
        SHOP_ERROR: {
            return {
                ...state,
                error: payload,
                loading: false
            };
        }

        default: {
            return state;
        }
    }
}
